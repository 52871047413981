const faq = [
  {
    question: "Do you ship your paintings?",
    answer: "Yes! I will be shipping from Denver, Colorado. Currently only shipping within the US and Canada."
  },
  {
    question: "How much is shipping?",
    answer: "Shipping depends on size and weight of piece, where it is going, as well as other factors. Email me with your address and piece you are interested in for a quote."
  },
  {
    question: "Do you sell prints?",
    answer: "Yes, I sell prints through Geo Galleries. Click Buy Prints at bottom of page or copy this address into your browser: https://geogalleries.com/esgart/"
  },
  {
    question: "Does it include a frame?",
    answer: "Most paintings do not include a frame. A frame can be included for additional cost."
  },
  {
    question: "Do you offer any discounts?",
    answer: "I offer discounts to charities and non-profit organizations. I also offer discounts to friends and family. Email me for details"
  },
  {
    question: "Do you take custom orders?",
    answer: "Send me an email with a photo or drawing as a reference along with the desired size, mediums, etc. If I am able, I will respond with a quote."
  },
  {
    question: "What forms of payment do you accept?",
    answer: "I accept cash, bank transfers, CashApp, Venmo, Paypal, Coinbase, Zelle. Donations welcome." 
  },
  {
    question: "Do you accept cryptocurrency as payment for art?",
    answer: "Yes I do! I accept Bitcoin, Cardano and Ethereum. All purchases made with crypto are 10% off. Donations welcome. ADA Handle: $esgart Coinbase wallet: @cirth" 
  },
  {
    question: "Do you negotiate prices?",
    answer: "Email me your best offer and I'll consider it. "
  }
];

export default faq;

// {
//   question: "",
//   answer: ""
// }
