import React from "react"
import {Button, Icon} from "semantic-ui-react"

const ButtonWrapper = (props) => {
  return (
    <div>
      <Button animated {...props}>
        <Button.Content visible>Buy</Button.Content>
        <Button.Content hidden>
          <Icon name={"shop"}/>
        </Button.Content>
      </Button>
    </div>
  )
}

export default ButtonWrapper
