import React, { useState } from "react";
import { jsx, css } from "@emotion/core";
/** @jsx jsx */

import SectionIntro from "./sections/SectionIntro";
import SectionPaintings from "./sections/SectionPaintings";
import SectionAboutMe from "./sections/SectionAboutMe";
import SectionFAQ from "./sections/SectionFAQ";
import SectionForm from "./sections/SectionForm";
import SectionFooter from "./sections/SectionFooter";
import DividerWrapper from "./components/DividerWrapper";

const body = css`
  background: #e5f5ee;
  p,
  h1,
  h2,
  div {
    //font-family: "Great Vibes", cursive;
  }
`;

const wrapper = css`
  background: #e5f5ee;
  display: flex;
  flex-direction: column;
  margin: 5rem 2.5rem;
  text-align: center;
  @media (min-width: 1200px) {
    max-width: 1200px;
    margin: 5rem auto 0;
  }
`;

function App() {
  const [paintingChosen, setPaintingChosen] = useState("");

  return (
    <div css={body}>
      <SectionIntro />
      <div css={wrapper}>
        <SectionPaintings handleBuy={e => setPaintingChosen(e)} />
        <DividerWrapper />
        <SectionAboutMe />
        <DividerWrapper />
        <SectionFAQ />
        <DividerWrapper />
        <SectionForm paintingChosen={paintingChosen} />
      </div>
      <SectionFooter />
    </div>
  );
}

export default App;
