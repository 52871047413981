import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";
import { css, jsx } from "@emotion/core";
/** @jsx jsx **/

const SectionForm = ({ paintingChosen = null }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [textAreaData, setTextAreaData] = useState(paintingChosen);

  useEffect(() => {
    if (paintingChosen) onBuy(paintingChosen);
  }, [paintingChosen]);

  function onBuy({ title, price }) {
    const buySentence = `I am interested in purchasing "${title}" for "${price}".`;
    setTextAreaData(buySentence);
  }

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  function handleSubmit(e) {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...{ name, email, message: textAreaData }
      })
    })
      .then(() => {
        alert("Successfully Sent!");
        setName("");
        setEmail("");
        setTextAreaData("");
      })
      .catch(error => alert(error));

    e.preventDefault();
  }

  return (
    <div id="form" css={formStyle}>
      <h2>Contact Me</h2>
      <form onSubmit={handleSubmit} className="ui form">
        <div className="equal width fields">
          <label className="field">
            Name:
            <input
              value={name}
              onChange={e => setName(e.target.value)}
              name="name"
              placeholder="your name"
              required
            />
          </label>
          <label className="field">
            Email:
            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              name="email"
              placeholder="your email"
              required
            />
          </label>
        </div>
        <label className="field">
          Message:
          <textarea
            name="textAreaData"
            onChange={e => setTextAreaData(e.target.value)}
            value={textAreaData}
            placeholder="Let me know what you are interested in"
          />
        </label>
        <Button style={{ marginTop: ".5rem" }} type="submit">
          Send
        </Button>
      </form>
    </div>
  );
};

const formStyle = css``;

export default SectionForm;
