import React from "react";
import { Image } from "semantic-ui-react";
import websiteStyles from "../data/websiteStyles";
import { css, jsx } from "@emotion/core";
/** @jsx jsx **/

const h1 = css`
  color: white;
  font-size: 2.8rem;
  text-align: center;
  text-shadow: 3px 3px 5px #000000;
`;

const wrapper = css`
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("/images/banner.jpg") no-repeat center;
  background-size: cover;
`;

const SectionIntro = () => {
  return (
    <div css={wrapper}>
      
      <Image
        src="/images/eman-painting-dragon-cropped.jpg"
        size="medium"
        alt="profile picture"
        circular
        avatar
      />
      <h1 css={h1}>Emmanuel S Germanov Art</h1>
    </div>
  );
};

export default SectionIntro;
