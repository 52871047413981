import React, { useEffect, useState } from "react";
import { Button, Card, Image, Modal } from "semantic-ui-react";
import paintingsData from "../data/paintings";
import ButtonWrapper from "../components/ButtonWrapper";
import websiteStyles from "../data/websiteStyles";
import { css, jsx } from "@emotion/core";
/** @jsx jsx **/

import LazyImage from "../components/LazyImage";

const SectionPaintings = ({ handleBuy }) => {
  const [paintings, setPaintings] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPainting, setOpenPainting] = useState({});
  const [buyModalOpen, setBuyModalOpen] = useState(false);

  useEffect(() => {
    // Sort "sold" to end of list
    const sortedPaintings = paintingsData.sort((a, b) => {
      return a.sold - b.sold;
    });
    setPaintings(sortedPaintings);
  }, []);

  function toggleSelectedPainting(painting) {
    setOpen(!open);
    // required when modal closes
    if (painting) setOpenPainting(painting);
  }

  function getButton(painting) {
    return painting.sold ? (
      <Button disabled color="red">
        Sold
      </Button>
    ) : (
      <ButtonWrapper
        onClick={e => purchase(e, painting)}
        animated={"fade"}
        style={{ background: websiteStyles.primaryColor, color: "white" }}
      />
    );
  }

  function purchase(e, painting) {
    e.stopPropagation();
    handleBuy(painting);
    window.scrollTo(0, document.body.scrollHeight);
    setBuyModalOpen(true);
  }

  const marginBottom = css`
    margin-bottom: 2.3rem;
  `;

  return (
    <div>
      <h1 css={marginBottom}>Gallery</h1>
      <Card.Group itemsPerRow={3} stackable centered>
        {paintings.map((painting, i) => (
          <Card
            key={i}
            image={
              <LazyImage
                alt={painting.title}
                src={`/images/paintings/${painting.smallImage}`}
              />
            }
            header={painting.title}
            meta={painting.price}
            description={painting.description}
            extra={getButton(painting)}
            onClick={() => toggleSelectedPainting(painting)}
            raised
          />
        ))}
      </Card.Group>
      {/*Large Image when clicked*/}
      <Modal
        open={open}
        onClose={() => toggleSelectedPainting()}
        header={openPainting.title}
        content={<Image src={`/images/paintings/${openPainting.largeImage}`} />}
        style={{ textAlign: "center" }}
        centered
        dimmer
      />
      {/*Purchase Modal*/}
      <Modal
        open={buyModalOpen}
        header="Please contact me about which what painting you are interested in"
        content="The form is located at the bottom"
        actions={[{ key: "ok", content: "Ok", positive: true }]}
        onClose={() => setBuyModalOpen(false)}
      />
    </div>
  );
};

export default SectionPaintings;
