const paintings = [
  {
    title: "Load Bearing Columns",
    smallImage: "small/load-bearing-columns-small.jpg",
    largeImage: "large/load-bearing-columns.jpg",
    description:
      'Acrylic on Canvas 16" X 20" ',
    price: "$500.00",
    sold: true
  },{
    title: "Crumbling Coastal Castle",
    smallImage: "small/crumbling-coastal-castle-small.jpg",
    largeImage: "large/crumbling-coastal-castle.jpg",
    description:
      'Acrylics on Canvas 16" X 20"',
    price: "$400",
    sold: true
  },
  {
    title: "Valley View",
    smallImage: "small/valley-view-small.jpg",
    largeImage: "large/valley-view.jpg",
    description:
      'Acrylics on Canvas Panel 9" X 12" View of San Luis Valley in Colorado.',
    price: "$130.00",
    sold: false
  },
  {
    title: "Megascrapers",
    smallImage: "small/megascrapers-small.jpg",
    largeImage: "large/megascrapers.jpg",
    description:
      'Acrylics on Canvas Panel 8" X 10" Wood frame included.',
    price: "$150.00",
    sold: false
  },
  {
    title: "The Great Sand Dunes",
    smallImage: "small/great-sand-dunes-small.jpg",
    largeImage: "large/great-sand-dunes.jpg",
    description:
      'Acrylic on Canvas 24" x 48"  North facing view from High Dune in the Great Sand Dunes National Park in Colorado. ',
    price: "$500.00",
    sold: true
  },
  {
    title: "Maroon Bells",
    smallImage: "small/maroon-bells-small.jpg",
    largeImage: "large/maroon-bells.jpg",
    description:
      'Acrylic on Canvas 24" x 36" "We are now in the mountains and they are in us, kindling enthusiasm, making every nerve quiver, filling every pore and cell of us." - John Muir',
    price: "$570.00",
    sold: true
  },
  {
    title: "Cathedral Spires",
    smallImage: "small/cathedral-spires-small.jpg",
    largeImage: "large/cathedral-spires.jpg",
    description:
      'Acrylic on Canvas 8" x 10" A small painting of the Cathedral Spires rock formation in Garden of the Gods, Colorado Springs. Black wood frame included.',
    price: "$100",
    sold: false
  },
  {
    title: "Kansas Sky",
    smallImage: "small/kansas-sky-small.jpg",
    largeImage: "large/kansas-sky.jpg",
    description:
      'Acrylics on Canvas 11" X 14" Inspired by a recent road trip through Kansas.',
    price: "$100",
    sold: false
  },
  {
    title: "Old Windmill",
    smallImage: "small/old-windmill-small.jpg",
    largeImage: "large/old-windmill.jpg",
    description:
      'Acrylics on Canvas 11" X 14" The self imposed challenge for this impressionist style painting was to only use a single flat 1/2" brush.',
    price: "$100",
    sold: false
  },
  {
    title: "Past and Present",
    smallImage: "small/past-and-present-small.jpg",
    largeImage: "large/past-and-present.jpg",
    description:
      'Acrylics on Canvas 11" X 14"',
    price: "$120",
    sold: false
  },
  {
    title: "View From Rangeview Trail",
    smallImage: "small/view-from-rangeview-trail-small.jpg",
    largeImage: "large/view-from-rangeview-trail.jpg",
    description:
      'Acrylics on Canvas 8" X 10" I tried painting the view from Rangeview Trail in Boulder, CO.',
    price: "$100",
    sold: true
  },
  {
    title: "Troglotowers",
    smallImage: "small/troglotowers-small.jpg",
    largeImage: "large/troglotowers.jpg",
    description:
      'Acrylics on Canvas 11" X 14" Wood frame included.',
    price: "$150",
    sold: true
  },
  {
    title: "Crumbling Tower",
    smallImage: "small/crumbling-tower-small.jpg",
    largeImage: "large/crumbling-tower.jpg",
    description:
      'Acrylics on Canvas 9" X 12"',
    price: "$120",
    sold: false
  },
  {
    title: "Shy Moon",
    smallImage: "small/shy-moon-small.jpg",
    largeImage: "large/shy-moon.jpg",
    description:
      'Acrylics on Canvas 8" X 10"',
    price: "$50",
    sold: true
  },
  {
    title: "Untamoinen",
    smallImage: "small/untamoinen-small.jpg",
    largeImage: "large/untamoinen.jpg",
    description:
      'Acrylics on Canvas 11" X 14"',
    price: "$75",
    sold: true
  },
  {
    title: "Big Tree",
    smallImage: "small/big-tree-small.jpg",
    largeImage: "large/big-tree.jpg",
    description:
      'Acrylics on Canvas 11" X 14"',
    price: "$50",
    sold: true
  },
  {
    title: "Blue Ridges",
    smallImage: "small/blue-ridges-small.jpg",
    largeImage: "large/blue-ridges.jpg",
    description:
      'Acrylics on Canvas 8" X 10"',
    price: "$40",
    sold: true
  },
  {
    title: "Ship at Sunset",
    smallImage: "small/ship-at-sunset-small.jpg",
    largeImage: "large/ship-at-sunset.jpg",
    description:
      'Acrylics on Canvas 5" X 7"',
    price: "$40",
    sold: true
  },
  {
    title: "Palace at Sunset",
    smallImage: "small/palace-at-sunset-small.jpg",
    largeImage: "large/palace-at-sunset.jpg",
    description:
      'Acrylics on Canvas 5" X 7"',
    price: "$40",
    sold: true
  },
  {
    title: "Sunset Ruins",
    smallImage: "small/sunset-ruins-small.jpg",
    largeImage: "large/sunset-ruins.jpg",
    description:
      'Acrylics on Canvas 5" X 7"',
    price: "$40",
    sold: true
  },
  {
    title: "Summit Lake",
    smallImage: "small/summit-lake-small.jpg",
    largeImage: "large/summit-lake.jpg",
    description:
      'Acrylic on Canvas 24" x 48" This is a small lake overlooking Mt. Rainier in Washington. I\'ve never been there myself, but I\'d love to see it with my own eyes. It makes me think of Erebor.',
    price: "$500.00",
    sold: true
  },
  {
    title: "Nightfall on Ra",
    smallImage: "small/nightfall-on-ra-small.jpg",
    largeImage: "large/nightfall-on-ra.jpg",
    description:
      'Acrylic on Canvas 36" x 48" Collaboration by Jasmine Panda Kief and me',
    price: "NA",
    sold: true
  },
  {
    title: "The Observers",
    smallImage: "small/the-observers-small.jpg",
    largeImage: "large/the-observers.jpg",
    description:
      'Acrylic on Canvas 12" x 9" They watch and wait.',
    price: "$100",
    sold: false
  },
  {
    title: "African Sunset",
    smallImage: "small/african-sunset-small.jpg",
    largeImage: "large/african-sunset.jpg",
    description:
      'Acrylic on Canvas 8" x 12" ',
    price: "NA",
    sold: true
  },
  {
    title: "Arrival",
    smallImage: "small/arrival-small.jpg",
    largeImage: "large/arrival.jpg",
    description:
      'Acrylic on Canvas 8" x 12" They stepped through the portal for the last time. At last! Their search had come to an end.',
    price: "NA",
    sold: true
  },
  {
    title: "Cabin In the Woods",
    smallImage: "small/cabin-in-the-woods-small.jpg",
    largeImage: "large/cabin-in-the-woods.jpg",
    description:
      'Acrylic on Canvas 12" x 12" Just a peaceful lakeside cabin at sunset.',
    price: "$100.00",
    sold: true
  },
  {
    title: "Cosmic Mother",
    smallImage: "small/cosmic-mother-small.jpg",
    largeImage: "large/cosmic-mother.jpg",
    description:
      'Acrylic on Canvas 24" x 36" Collaboration by Altaira Breslin and I. Altaira painted the right wing, I did the left. ',
    price: "NA",
    sold: true
  },
  {
    title: "Hogwarts",
    smallImage: "small/hogwarts-small.jpg",
    largeImage: "large/hogwarts.jpg",
    description:
      '"Acrylic on Canvas 8" x 11"',
    price: "NA",
    sold: true
  },
  {
    title: "Monkey",
    smallImage: "small/monkey-small.jpg",
    largeImage: "large/monkey.jpg",
    description:
      'Acrylic on Canvas 24"x 18"',
    price: "NA",
    sold: true
  },
  {
    title: "Mountain River",
    smallImage: "small/mountain-river-small.jpg",
    largeImage: "large/mountain-river.jpg",
    description:
      'Acrylic on Canvas 24" x 18"',
    price: "$100.00",
    sold: true
  },
  {
    title: "Mountain Autumn",
    smallImage: "small/mountains-autumn-small.jpg",
    largeImage: "large/mountains-autumn.jpg",
    description:
      'Acrylic on Canvas 24" x 18"',
    price: "$100.00",
    sold: true
  },
  {
    title: "Purple Sunset",
    smallImage: "small/purple-sunset-over-lake-small.jpg",
    largeImage: "large/purple-sunset-over-lake.jpg",
    description:
      'Acrylic on Canvas 12"x 12" Inspired by Colorado sunsets. Palette knives only.',
    price: "$100.00",
    sold: true
  },
  {
    title: "Tranquil Morning",
    smallImage: "small/tranquil-morning-small.jpg",
    largeImage: "large/tranquil-morning.jpg",
    description:
      'Acrylic on Canvas 12" x 12"',
    price: "$100.00",
    sold: true
  },
  {
    title: "Urban Extraterrestrial",
    smallImage: "small/urban-extraterrestrial-small.jpg",
    largeImage: "large/urban-extraterrestrial.jpg",
    description:
      'Palette Knife Acrylics on Canvas 36" x 48" Collaboration by Jasmine Panda Kief and me. Jasmine and I only used palette knives to paint this one.',
    price: "NA",
    sold: true
  },
  {
    title: "Yosemite, 3674 CE",
    smallImage: "small/yosemite-3674-ce-small.jpg",
    largeImage: "large/yosemite-3674-ce.jpg",
    description:
      'Acrylic on Canvas Panel, 14" x 18" Black frame included. ',
    price: "$150.00",
    sold: false
  }
];

export default paintings;

// {
//   title: "",
//   smallImageLocation: "",
//   largeImageLocation: "",
//   description: "",
//   sold: false/true,
// }
