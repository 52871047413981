import React, { useEffect, useState } from "react";
import { Grid, Image, Segment } from "semantic-ui-react";
import Carousel from "semantic-ui-carousel-react";
import aboutMeData from "../data/aboutMe";

import LazyImage from "../components/LazyImage";

function generateElements(photos = []) {
  return photos.map(photo => {
    return {
      render: () => {
        return <Image src={photo.image} />;
      }
    };
  });
}

const SectionAboutMe = () => {
  const [aboutMeList, setAboutMeList] = useState([]);

  // If we decide to add the carousel back
  const [aboutMePictures, setAboutMePictures] = useState([]);

  useEffect(() => {
    setAboutMeList(aboutMeData.aboutMeList);
    const generatedPictures = generateElements(aboutMeData.aboutMePhotos);
    setAboutMePictures(generatedPictures);
  }, []);
  return (
    <div>
      <h2>Artist Bio</h2>
      <Grid centered stackable padded="vertically">
        <Grid.Row stretched>
          <Grid.Column width={7}>
            {/* <Carousel
              elements={aboutMePictures}
              duration={6500}
              animation="slide left"
            /> */}
            <LazyImage
              rounded
              src="/images/eman-at-chipolte.jpg"
              alt="About Me Eman Profile Picture"
            />
          </Grid.Column>
          <Grid.Column width={9}>
            {aboutMeList.map((aboutMeItem, i) => (
              <Segment key={i} raised>
                {aboutMeItem.content}
              </Segment>
            ))}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default SectionAboutMe;
