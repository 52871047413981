import React from "react";
import { Icon } from "semantic-ui-react";
import websiteStyles from "../data/websiteStyles";
import { css, jsx } from "@emotion/core";
/** @jsx jsx **/

const footerStyle = css`
  background: #6a6777;
  color: white;
  padding: 1.1rem;
  margin-top: 3rem;

  a {
    color: white;
    margin-left: 2rem;
    display: block;
  }

  .inner {
    padding: 0 3rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;

      span {
        margin-top: 0.5rem;
        text-align: center;
      }
    }
  }

  .social-contact {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`;

const SectionFooter = () => {
  return (
    <div css={footerStyle}>
      <div className="inner">
        <span>
          <Icon name="copyright outline" />
          ESG Art {new Date().getFullYear()}
        </span>
        <div className="social-contact">
          <a href="mailto:emmanuelgermanovart@gmail.com ">
            <Icon name="mail outline" />
            emmanuelgermanovart@gmail.com
          </a>
          <a href="https://instagram.com/e.s.g.art">
            <Icon name="instagram" />
            e.s.g.art
          </a>
          <a href="https://geogalleries.com/esgart/">
            <Icon name="Geo Galleries" />
            Buy Prints
          </a>
        </div>
      </div>
    </div>
  );
};

export default SectionFooter;
