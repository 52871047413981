const aboutMe = {
  aboutMeList: [
    {
      content:
        "I am a self-taught artist living in Colorado. I was born in Moldova and emigrated to the U.S. when I was young. I spend much of my time outdoors in the Rocky Mountains, whose majestic skies and mountains inspire me. I like to explore the disused and abandoned and often examine these themes with my art."
    },
    {
      content:
        "As I paint, I like to imagine that I am peering through a window into a different reality. Much of my work is an attempt at visualizing other worlds and states of being. I wish to convey the same sense of mystery and wonder that I feel when I see these mythical, unreal environments. I want to create scenes that tell a story and evoke a desire to explore them."
    }
  ],

  //if we ever add the carousel again
  aboutMePhotos: [
    {
      title: "",
      image: "/images/FB-photo.jpg",
      description: "fb"
    },
    {
      title: "",
      image: "/images/very-large.jpg",
      description: "large photo"
    },
    {
      title: "",
      image: "/images/stylish-suit.jpg",
      description: "me in a stylish suit"
    },
    {
      title: "",
      image: "/images/cool-dudes.jpg",
      description: "coolest guys around"
    }
  ]
};

export default aboutMe;
