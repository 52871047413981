import React from "react";
import { Divider } from "semantic-ui-react";
import websiteStyles from "../data/websiteStyles";
import { css, jsx } from "@emotion/core";
/** @jsx jsx **/

const dividerStyle = css`
  margin-top: 3rem;
  margin-bottom: 3rem;
  background: #6a6777;
  border-radius: 0%;
`;

const DividerWrapper = () => {
  return (
    <div css={dividerStyle}>
      <Divider section />
    </div>
  );
};

export default DividerWrapper;
