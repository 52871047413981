import React, { useState } from "react";
import { Visibility, Image, Loader } from "semantic-ui-react";

function LazyImage(props) {
  const [show, setShow] = useState(false);

  if (!show) {
    return (
      <Visibility as="span" onOnScreen={() => setShow(true)}>
        <Loader active inline="centered" />
      </Visibility>
    );
  } else {
    return <Image {...props} />;
  }
}

export default LazyImage;
