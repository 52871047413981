import React, { useEffect, useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import faq from "../data/faq";
import { css, jsx } from "@emotion/core";
/** @jsx jsx **/

const SectionFAQ = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    setFaqs(faq);
  }, []);

  function handleClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  }

  const faqStyle = css`
    user-select: none;
    font-size: 1.2rem;
  `;

  return (
    <div css={faqStyle}>
      <h2>FAQ</h2>
      <Accordion fluid styled css={faqStyle}>
        {faqs.map((item, i) => (
          <div key={i}>
            <Accordion.Title
              active={activeIndex === i}
              index={i}
              onClick={handleClick}
            >
              <Icon name="dropdown" />
              {item.question}
            </Accordion.Title>
            <Accordion.Content active={activeIndex === i}>
              <p>{item.answer}</p>
            </Accordion.Content>
          </div>
        ))}
      </Accordion>
    </div>
  );
};

export default SectionFAQ;
